import React, { useState, useEffect } from "react";
import classnames from "classnames";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";

import "./index.scss";
import Card from "../Card";
import Timeline from "../Timeline";
import Preloader from "../../Preloader";
import Image from "../../Image/Image";

const Mainpage = () => {
    const [type, setType] = useState("news");
    const [news, setNews] = useState({});
    const [isFetched, setFetched] = useState(false);
    const [articlesFetching, setArticlesFetching] = useState(false);
    const [timelineOffset, setTimelineOffset] = useState(1);
    const [widgetData, setWidgetData] = useState({});

    useEffect(() => {
        axios.post(`/getMainPage`, {}).then(function (response) {
            setNews(response.data);
            setFetched(true);
        });
        axios.post(`/getWidgetData`, {}).then(function (response) {
            setWidgetData(response.data)
        });
    }, []);

    useEffect(() => {
        if (articlesFetching) {
            setTimelineOffset((prevState) => prevState + 1);
        }
    }, [articlesFetching]);

    useEffect(() => {
        if (type === "timeline") {
            document.addEventListener("scroll", scrollHandler);
            return function () {
                document.removeEventListener("scroll", scrollHandler);
            };
        }
    }, [type]);

    const scrollHandler = (e) => {
        const footerHeight = document.querySelector("footer").scrollHeight;
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) - footerHeight < 300) {
            setArticlesFetching(true);
        }
    };

    return (
        <div className="mainpage">
            <div className="container">
                <div
                    className={classnames("mainpage__content", {
                        mainpage__news: type === "news",
                        mainpage__timeline: type === "timeline",
                    })}
                >
                    <main>
                        <div className="mainage_widget">
                            {
                                widgetData.weatherData &&
                                <>
                                    <div className="header__wc_block">
                                        <div className="header__wc_title">Погода</div>
                                        <div className="header__weather">
                                            <div>
                                                <img src={`https://openweathermap.org/img/wn/${widgetData.weatherData?.icon}.png`} alt="" />
                                            </div>
                                            <span className="header__weather_num">{Math.round(widgetData.weatherData?.temperature)}</span>°C
                                        </div>
                                    </div>
                                    <div className="header__wc_block">
                                        <div className="header__wc_title">Пробки</div>
                                        <div className="header__traffic" style={{ "border-color": widgetData.probki?.color }}>
                                            {widgetData.probki?.level}
                                        </div>
                                    </div>
                                    <div className="header__currency">
                                        <div className="header__currency_item">$ {widgetData.money?.usd}</div>
                                        <div className="header__currency_item">€ {widgetData.money?.eur}</div>
                                    </div>
                                </>
                            }
                        </div>
                        <a target="_blank" rel="nofollow noreferrer" href="https://contract.gosuslugi.ru/?ysclid=lhbw2ymi74462749248" className="mainpage__contract">
                            <Image src={process.env.PUBLIC_URL + "images/contract.jpg"} alt={"Служба по контракту"} />
                        </a>
                        <div className="mainpage__type_switch">
                            <div
                                className={classnames("mainpage__type_switch_btn", {
                                    mainpage__type_switch_btn_active: type === "news",
                                })}
                                onClick={() => {
                                    setType("news");
                                }}
                            >
                                <h1>Все новости</h1>
                            </div>
                            <div
                                className={classnames("mainpage__type_switch_btn", {
                                    mainpage__type_switch_btn_active: type === "timeline",
                                })}
                                onClick={() => {
                                    setType("timeline");
                                }}
                            >
                                <h1>Лента</h1>
                            </div>
                        </div>
                        {isFetched ? (
                            <div className="mainpage__type_news_content">
                                {news.main.length > 0 && (
                                    <section className="mainpage__main_news mainpage__bordered_block">
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={1}
                                            loop={true}
                                            modules={[Navigation]}
                                            navigation={{
                                                nextEl: ".mainpage__main_next",
                                                prevEl: ".mainpage__main_prev",
                                            }}
                                            className="mainpage__main_slider"
                                        >
                                            {news.main.map((article, i) => (
                                                <SwiperSlide>
                                                    <Card article={article} type="big"></Card>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_prev mainpage__main_prev" name="prev">
                                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                    fill="#222222"
                                                />
                                            </svg>
                                        </button>
                                        <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_next mainpage__main_next" name="next">
                                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                    fill="#222222"
                                                />
                                            </svg>
                                        </button>
                                    </section>
                                )}
                                <section className="mainpage__rr mainpage__bordered_block">
                                    <div className="mainpage__rr_card">
                                        <div className="mainpage__rr_card_bg">
                                            <img src={process.env.PUBLIC_URL + "images/rate-bg.svg"} alt="bg" />
                                        </div>
                                        <h3 className="mainpage__rr_card_title">Рейтинги и награды города</h3>
                                        <div className="mainpage__rr_card_text">Узнайте в чем ваш город лучший, следите за рейтингами, выражайте мнение</div>
                                        <a href="/rate" className="mainpage__rr_card_link">Смотреть награды</a>
                                    </div>
                                    <div className="mainpage__rr_card">
                                        <div className="mainpage__rr_card_bg">
                                            <img src={process.env.PUBLIC_URL + "images/reforms-bg.svg"} alt="bg" />
                                        </div>
                                        <h3 className="mainpage__rr_card_title">Проекты и реформы</h3>
                                        <div className="mainpage__rr_card_text">Узнайте во сколько в среднем обходится жизнь в Санкт-Петербурге и сколько людей трудятся в разных сферах.</div>
                                        <a href="/reforms" className="mainpage__rr_card_link">Изучить</a>
                                    </div>
                                </section>
                                {news.actual && (
                                    <section className="mainpage__actual">
                                        <header className="mainpage__title">
                                            <h3>Актуальное</h3>
                                            <div className="mainpage__slider_nav">
                                                <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_prev mainapge__actual_prev" name="prev">
                                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                            fill="#222222"
                                                        />
                                                    </svg>
                                                </button>
                                                <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_next mainapge__actual_next" name="next">
                                                    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                            fill="#222222"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </header>
                                        <Swiper
                                            spaceBetween={20}
                                            slidesPerView={"auto"}
                                            modules={[Navigation]}
                                            navigation={{
                                                nextEl: ".mainapge__actual_next",
                                                prevEl: ".mainapge__actual_prev",
                                            }}
                                            className="mainpage__actual_slider"
                                        >
                                            {news.actual.map((article, i) => (
                                                <SwiperSlide>
                                                    <Card key={i} article={article} type="title-bottom"></Card>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </section>
                                )}
                                {news.bigCategories?.map((category, i) => {
                                    return (
                                        Boolean(category.news.length) && (
                                            <section key={i} className={i == 2 ? "mainpage__category mainpage__bordered_block economy_decktop" : "mainpage__category mainpage__bordered_block"}>
                                                <h3 className="mainpage__title">{category.title}</h3>
                                                <div className="mainpage__category_news">
                                                    <Card article={category.news[0]} type="img-down"></Card>
                                                    <div className="mainpage__category_list">
                                                        {category.news.map((article, i) => {
                                                            if (i > 0) {
                                                                return <Card key={i} article={article} type="title-right"></Card>;
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </section>
                                        )
                                    );
                                })}
                                <section className="mainpage__categories_list economy_mobile">
                                    <div className="mainpage__category_compilation">
                                        <h3 className="mainpage__title">{news.bigCategories[2].title}</h3>
                                        <div className="mainpage__category_compilation_news">
                                            <Card article={news.bigCategories[2].news[0]} type="title-bottom-ns"></Card>
                                            {news.bigCategories[2].news?.map((article, i) => {
                                                if (i > 0) {
                                                    return <Card key={i} article={article} type="only-title"></Card>;
                                                }
                                            })}
                                        </div>
                                    </div>
                                </section>
                                {news.smallCategories?.map((categoriesList, i) => {
                                    return (
                                        Boolean(categoriesList.length) && (
                                            <section key={i} className="mainpage__categories_list">
                                                {categoriesList.map((category, i) => {
                                                    return (
                                                        Boolean(category.news.length) && (
                                                            <div key={i} className="mainpage__category_compilation">
                                                                <h3 className="mainpage__title">{category.title}</h3>
                                                                <div className="mainpage__category_compilation_news">
                                                                    <Card article={category.news[0]} type="title-bottom-ns"></Card>
                                                                    {category.news?.map((article, i) => {
                                                                        if (i > 0) {
                                                                            return <Card key={i} article={article} type="only-title"></Card>;
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )
                                                    );
                                                })}
                                            </section>
                                        )
                                    );
                                })}
                                {/* { news.authors &&
                                <section className="mainpage__authors">
                                    <div className='mainpage__title'>
                                        <h3>Авторы</h3>
                                        <div className="mainpage__slider_nav">
                                            <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_prev mainapge__authors_prev">
                                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z" fill="#222222"/>
                                                </svg>
                                            </button>
                                            <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_next mainapge__authors_next">
                                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z" fill="#222222"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <Swiper
                                        spaceBetween={20}
                                        slidesPerView={'auto'}
                                        modules={[Navigation]}
                                        navigation={{
                                            nextEl: '.mainapge__authors_next',
                                            prevEl: '.mainapge__authors_prev'
                                        }}
                                        className="mainpage__authors_slider">
                                        {
                                            news.authors.map((article, i) =>
                                                <SwiperSlide>
                                                    <Card key={i} article={article} type='author'></Card>
                                                </SwiperSlide>
                                            )
                                        }
                                    </Swiper>
                                </section>
                            } */}
                            </div>
                        ) : (
                            <Preloader></Preloader>
                        )}
                    </main>
                    <Timeline page={timelineOffset} setPage={setTimelineOffset} setArticlesFetching={setArticlesFetching} type={type}></Timeline>
                </div>
            </div>
        </div>
    );
};

export default Mainpage;
