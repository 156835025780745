import React, { useState } from "react";
import { setState } from "react";
import { useEffect, useRef, setActive } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./index.scss";
import axios from "axios";

import Modal from '../Header/modal'
import Image from '../Image/Image'

const Profile = () => {
    const [profileData, setProfileData] = useState({
        name: "",
        lastname: "",
        email: "",
    });
    const [exitModalOpen, setExitModalOpen] = useState(false);
    const [exit, setExit] = useState('exit')

    const [profilePhoto, setProfilePhoto] = useState();
    function profilePhotoChange(e) {
        setProfilePhoto(URL.createObjectURL(e.target.files[0]));
        setChangePhotoModal(false);
    }

    const photoInput = useRef();

    function uploadProfilePhoto() {
        const btn = photoInput.current;
        btn.click();
    }

    const [changePhotoModal, setChangePhotoModal] = useState(false);

    function changeProfilePhoto(image) {
        setChangePhotoModal(false);
        setProfilePhoto("/uploads/" + image)
        axios({
            url: "/changeAvatar",
            method: "post",
            data: {photo: image},
            headers: { "Content-Type": "application/json" },
        });
    }

    const [errorState, setErrorState] = useState("error_password");
    const [validate, setValidate] = useState({
        email: true,
        password: true,
        passwordsMatch: true,
        notEmpty: true,
    });

    const handleSubmit = (event) => {
        let data = Object.fromEntries(new FormData(event.target).entries());

        let notEmpty = true;
        Object.keys(data).map((key) => {
            if (!data[key].length) {
                notEmpty = false;
            }
        });

        let re = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
        let myMail = data.email;
        let mailValidate = myMail ? re.test(myMail) : true;

        let passwordValidate = true;
        let passwordMatch = true;

        let passwordFirst = data.password;
        let passwordSecond = data.password_2;
        if ((passwordFirst?.length < 6) & (passwordFirst?.length != 0)) {
            passwordValidate = false;
        } else if (passwordFirst !== passwordSecond) {
            passwordMatch = false;
        }

        setValidate({
            ...validate,
            email: mailValidate,
            notEmpty: notEmpty,
            password: passwordValidate,
            passwordsMatch: passwordMatch,
        });

        if (mailValidate && passwordValidate && passwordMatch) {
            setProfileData({...profileData, email: data.email, name: data.name, lastname: data.lastname});
            changeSettings(event);
        }
    };

    const changeSettings = async (event) => {
        try {
            const res = await axios({
                url: "/changeSettings",
                method: "post",
                data: new FormData(event?.target),
                headers: { "Content-Type": "multipart/form-data" },
            });
        } catch (error) {console.log(error)}
    };
    useEffect(() => {
        let getMeData = axios.post("/getMe", {}).then((data) => {
            setProfileData(data.data);
            setProfilePhoto(`/uploads/${data.data.photo}`);
        });
        // changeSettings();
        const closeListener = window.addEventListener('click', function (e) {
            if (!e.target.closest('profile__photo_modal') 
                && !e.target.classList.contains('profile__info__right__profile-picture__btn-change')
                && !e.target.classList.contains('input__hidden')
            ) {
                setChangePhotoModal(false);
            }
        })
        return () => window.removeEventListener('click', closeListener)
    }, []);

    return (
        <div className="wrapper">
            <Modal open={exitModalOpen} setOpen={setExitModalOpen} exit={exit}></Modal>
            <div className="profile__container">
                <h1 className="profile__title">Настройка профиля</h1>
                <div className="profile__info">
                    <div className="profile__info__left">
                        <div className="profile__info__left__text">
                            <h6 className="profile__info__left__text-title">
                                {profileData.name} {profileData?.lastname}
                            </h6>
                            <p className="profile__info__left__text-email">{profileData.email}</p>
                        </div>
                        <button className="profile__info__left__btn-logout" onClick={() => setExitModalOpen(true)}>Выход</button>
                    </div>
                    <div className="profile__info__right">
                        <div className="profile__info__right__profile-picture">
                            {changePhotoModal && <div className="profile__photo_modal">
                                <div className="profile__photo_modal_select">
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_1.jpg")}>
                                        <Image src={"/uploads/default_profile_1.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_2.jpg")}>
                                        <Image src={"/uploads/default_profile_2.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_3.jpg")}>
                                        <Image src={"/uploads/default_profile_3.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_4.jpg")}>
                                        <Image src={"/uploads/default_profile_4.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_5.jpg")}>
                                        <Image src={"/uploads/default_profile_5.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_6.jpg")}>
                                        <Image src={"/uploads/default_profile_6.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_7.jpg")}>
                                        <Image src={"/uploads/default_profile_7.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_8.jpg")}>
                                        <Image src={"/uploads/default_profile_8.jpg"} alt={"Avatar"} />
                                    </div>
                                    <div className="profile__photo_modal_select_item" onClick={() => changeProfilePhoto("default_profile_9.jpg")}>
                                        <Image src={"/uploads/default_profile_9.jpg"} alt={"Avatar"} />
                                    </div>
                                </div>
                                <button className="profile__info__right__profile-picture__btn-change" onClick={(e) => uploadProfilePhoto()}>
                                    Загрузить
                                </button>
                            </div>} 

                            <div className="profile__info__right__profile-picture__photo">
                                <img src={profilePhoto} alt="profile picture" />
                                {/* <img src={process.env.PUBLIC_URL + "/images/profile/default_profile_1.jpg"} alt={"Avatar"} /> */}
                            </div>
                            <button className="profile__info__right__profile-picture__btn-change" onClick={(e) => setChangePhotoModal(true)}>
                                Изменить
                            </button>
                        </div>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(e);
                            }}
                            className="profile__info__right__form"
                        >
                            <input type="file" name="photo" id="photo" accept="image/*" onChange={profilePhotoChange} ref={photoInput} className="input__hidden" />
                            {!profileData.socialId && <input maxLength={60} className={validate.email ? "profile__info__right__form__input" : "profile__info__right__form__input profile__info__right__form__input__error"} name="email" type="email" placeholder="E-mail *" defaultValue={profileData.email} />}
                            <input maxLength={25} className="profile__info__right__form__input" name="name" type="text" placeholder="Имя *" defaultValue={profileData.name} />
                            <input maxLength={25} className="profile__info__right__form__input" name="lastname" type="text" placeholder="Фамилия" defaultValue={profileData?.lastname} />
                            {!profileData.socialId && <input className={validate.password && validate.passwordsMatch ? "profile__info__right__form__input" : "profile__info__right__form__input profile__info__right__form__input__error"} name="password" type="password" placeholder="Пароль *" />}
                            {!validate.password && <div className="profile__login_form_error profile__login_form_error_psw">Пароль должен содержать не меньше 6 символов</div>}
                            {!validate.passwordsMatch && <div className="profile__login_form_error profile__login_form_error_psw">Пароли не совпадают</div>}
                            {!profileData.socialId && <input className={validate.password && validate.passwordsMatch ? "profile__info__right__form__input" : "profile__info__right__form__input profile__info__right__form__input__error"} name="password_2" type="password" placeholder="Пароль еще раз *" />}
                            <input type="submit" value="Сохранить данные" className="profile__info__right__form__submit" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
