import React, { useState } from "react";
import "./RateReformTemplate.scss";

export const RateReformTemplate = ({ icon, bg, title, children, id, color, mainColor }) => {
    const webpImg = bg?.split(".")[0] + ".webp";
    const [imgChecked, setImgChecked] = useState(false);

    return (
        <div className="rrt" id={id}>
            <div className="rrt__preview">
                <div className="rrt__preview_bg">
                    <picture>
                        <source srcSet={webpImg} alt={title} type="image/webp" />
                        <img
                            src={bg?.split(".")[1] === "png" ? bg?.split(".")[0] + ".jpg" : bg}
                            alt={title}
                            onError={(e) => {
                                if (!imgChecked) {
                                    setImgChecked(true);
                                    e.target.parentNode.children[0].srcset = bg?.split(".")[1] === "png" ? bg?.split(".")[0] + ".jpg" : bg;
                                }
                            }}
                        />
                    </picture>
                </div>
                <div className="rrt__title" style={{ backgroundColor: color }}>
                    <div className="rrt__title_icon">
                        <img src={icon} alt="" />
                    </div>
                    <h2 className="rrt__title_text" style={{ color: mainColor }}>
                        {title}
                    </h2>
                </div>
            </div>
            <div className="rrt__content">{children}</div>
        </div>
    );
};
