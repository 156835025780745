import React from 'react';
import './RateReformLinks.scss'

export const RateReformLinks = ({title, text, data}) => {

    return (
        <div className="rrl">
            <ul className="rrl__links">
                {data.map(r => (
                    <li className="rrl__links_el">
                        <a href={`#${r.id}`} className="rrl__links_el_link">
                            <img src={r.icon} alt="#" />
                        </a>
                    </li>
                ))}
            </ul>
            <h1 className="rrl__title">{title}</h1>
            <div className="rrl__text">{text}</div>
        </div> 
    )
}