import { React, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import "./index.scss";
import Card from "../Card";
import Timeline from "../Timeline";
import SearchForm from "./form";
import noResultIcon from "../../../assets/image/no-result-icon.svg";
import Preloader from "../../Preloader";
import { Helmet } from "react-helmet";
import NotFound from "../../NotFound";
import translit from "../../../utils/translit";

import vImage from "./image.jpg";
import saImage from "./silver_age.png";
import saImage2 from "./silver_age_2.png";

const Search = () => {
    const [request, setRequest] = useState({ date: "full", sortBy: "date" });
    const [result, setResult] = useState({ articles: [], count: 0 });
    const [formInfo, setFormInfo] = useState({});
    const [articlesOffset, setArticlesOffset] = useState(2);
    const [articlesFetching, setArticlesFetching] = useState(false);
    const [emptyArticles, setemptyArticles] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [desc, setDesc] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();
    const [notFound, setNotFound] = useState(false);
    const [activeImage, setActiveImage] = useState("");

    useEffect(() => {
        document.title = "Новости Петербурга - Поиск";
        if (params.content) {
            const content = translit(params.content, true);
            if (content === 404) {
                setNotFound(true);
            } else {
                setRequest({ ...request, category: content });
            }
        } else if (searchParams.has("text")) {
            setRequest({ ...request, text: searchParams.get("text") });
            setFormInfo({ ...formInfo, text: searchParams.get("text") });
        } else {
            setNotFound(true);
        }
    }, []);

    useEffect(() => {
        let url;
        if (request.category) {
            url = "tag";
            switch (request.category) {
                case "Благоустройства": {
                    document.title = `Новости и события, связанные с вопросами улучшения городской среды и обустройства городских территорий - Петербург Онлайн`;
                    setDesc(
                        "Новости и события, связанные с вопросами улучшения городской среды и обустройства городских территорий. Мы предоставляем своим читателям информацию о новых проектах и реализуемых идеях, связанных с благоустройством города, а также обсуждениях и мероприятиях, направленных на улучшение экологической, социальной и культурной сфер города. - Петербург Онлайн"
                    );
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости городской среды",
                        subtitle:
                            "Новости и события, связанные с вопросами улучшения городской среды и обустройства городских территорий. Мы предоставляем своим читателям информацию о новых проектах и реализуемых идеях, связанных с благоустройством города, а также обсуждениях и мероприятиях, направленных на улучшение экологической, социальной и культурной сфер города",
                    });
                    break;
                }
                case "Федеральные новости": {
                    document.title = `Новости и события на федеральном уровне - Петербург Онлайн`;
                    setDesc("Новости и события на федеральном уровне. Здесь вы найдете информацию о политических, экономических, социальных и культурных событиях в стране, а также о последних решениях и законопроектах, принимаемых в федеральном правительстве. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости федерального уровня",
                        subtitle: "Новости и события на федеральном уровне. Здесь вы найдете информацию о политических, экономических, социальных и культурных событиях в стране, а также о последних решениях и законопроектах, принимаемых в федеральном правительстве",
                    });
                    break;
                }
                case "Город": {
                    document.title = `Новости и события, происходящие в вашем городе - Петербург Онлайн`;
                    setDesc(
                        "Новости и события, происходящие в вашем городе. Мы публикуем новости о политике, экономике, культуре, спорте и других сферах жизни города. Будьте в курсе последних событий, изменений в инфраструктуре и новых проектов, которые могут повлиять на вашу жизнь. - Петербург Онлайн"
                    );
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Местные новости",
                        subtitle: "Новости о события, происходящие в вашем городе. Мы публикуем новости о политике, экономике, культуре, спорте и других сферах жизни города. Будьте в курсе последних событий, изменений в инфраструктуре и новых проектов, которые могут повлиять на вашу жизнь",
                    });
                    break;
                }
                case "Здравоохранение": {
                    document.title = `Новости и события, связанные с медициной и здоровьем - Петербург Онлайн`;
                    setDesc("Новости и события, связанные с медициной и здоровьем. Здесь вы сможете узнать о последних исследованиях, новых методах лечения и профилактики различных заболеваний, изменениях в системе здравоохранения и других важных событиях в этой сфере. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости здравоохранения и медицины",
                        subtitle: "Новости и события, связанные с медициной и здоровьем. Здесь вы сможете узнать о последних исследованиях, новых методах лечения и профилактики различных заболеваний, изменениях в системе здравоохранения и других важных событиях в этой сфере",
                    });
                    break;
                }
                case "Социальные вопросы": {
                    document.title = `Новости и события, связанные с социальной сферой - Петербург Онлайн`;
                    setDesc("Новости и события, связанные с социальной сферой. Здесь вы сможете узнать о последних изменениях в законодательстве, новых социальных проектах, различных мероприятиях и других важных событиях в этой области. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости социальной сферы",
                        subtitle: "Новости и события, связанные с социальной сферой. Здесь вы сможете узнать о последних изменениях в законодательстве, новых социальных проектах, различных мероприятиях и других важных событиях в этой области",
                    });
                    break;
                }
                case "Транспорт": {
                    document.title = `Новости и события, связанные с автомобильным, железнодорожным, воздушным и другим видам транспорта - Петербург Онлайн`;
                    setDesc(
                        "Новости и события, связанные с автомобильным, железнодорожным, воздушным и другим видам транспорта. Здесь вы сможете узнать о последних изменениях в законодательстве, новых технологиях и разработках, происшествиях на дорогах и других важных событиях в этой области. - Петербург Онлайн"
                    );
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости транспортной инфраструктуры",
                        subtitle:
                            "Новости и события, связанные с автомобильным, железнодорожным, воздушным и другим видам транспорта. Здесь вы сможете узнать о последних изменениях в законодательстве, новых технологиях и разработках, происшествиях на дорогах и других важных событиях в этой области",
                    });
                    break;
                }
                case "Экономика": {
                    document.title = `Новости и события, связанные с экономической ситуацией в стране и мире - Петербург Онлайн`;
                    setDesc("Новости и события, связанные с экономической ситуацией в стране и мире. Здесь вы сможете узнать о последних изменениях в экономике, новых проектах и инициативах, аналитических обзорах и прогнозах экономического развития. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости экономики Санкт-Петербурга",
                        subtitle: "Новости и события, связанные с экономической ситуацией в стране и мире. Здесь вы сможете узнать о последних изменениях в экономике, новых проектах и инициативах, аналитических обзорах и прогнозах экономического развития",
                    });
                    break;
                }
                case "Образование": {
                    document.title = `Новости и события, связанные с образовательной сферой - Петербург Онлайн`;
                    setDesc("Новости и события, связанные с образовательной сферой. Здесь вы сможете узнать о последних изменениях в законодательстве, новых методиках обучения, актуальных проблемах и достижениях в образовании. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости об образовании",
                        subtitle: "Новости и события, связанные с образовательной сферой. Здесь вы сможете узнать о последних изменениях в законодательстве, новых методиках обучения, актуальных проблемах и достижениях в образовании",
                    });
                    break;
                }
                case "Наука и Технологии": {
                    document.title = `Новости и события в научных и технических областях - Петербург Онлайн`;
                    setDesc("Новости и события в научных и технических областях. Здесь вы сможете узнать о последних достижениях в мире науки, новых технологиях, инновационных разработках, медицинских и биологических открытиях, экологии и многом другом. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости научных исследований и технологического прогресса",
                        subtitle: "Новости и события в научных и технических областях. Здесь вы сможете узнать о последних достижениях в мире науки, новых технологиях, инновационных разработках, медицинских и биологических открытиях, экологии и многом другом",
                    });
                    break;
                }
                case "Культура": {
                    document.title = `Новости и статьи о культурной жизни в стране и за ее пределами - Петербург Онлайн`;
                    setDesc(
                        "Новости и статьи о культурной жизни в стране и за ее пределами. Мы следим за последними тенденциями в сфере искусства, музыки, кино, театра и литературы, рассказываем о грядущих мероприятиях и фестивалях, проводим интервью с известными художниками и актерами. - Петербург Онлайн"
                    );
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости искусства и культуры",
                        subtitle: "Новости и статьи о культурной жизни в стране и за ее пределами. Мы следим за последними тенденциями в сфере искусства, музыки, кино, театра и литературы, рассказываем о грядущих мероприятиях и фестивалях, проводим интервью с известными художниками и актерами",
                    });
                    break;
                }
                case "Спорт": {
                    document.title = `Новости и события из мира спорта - Петербург Онлайн`;
                    setDesc(
                        "Новости и события из мира спорта. Здесь вы сможете узнать о последних результатам соревнований, турнирах и чемпионатах в разных видах спорта. Мы следим за мировыми и национальными командами, выдающимися спортсменами, и предоставляем свежие новости и интересные факты из этой области. - Петербург Онлайн"
                    );
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости о спорте",
                        subtitle:
                            "Новости и события из мира спорта. Здесь вы сможете узнать о последних результатам соревнований, турнирах и чемпионатах в разных видах спорта. Мы следим за мировыми и национальными командами, выдающимися спортсменами, и предоставляем свежие новости и интересные факты из этой области",
                    });
                    break;
                }
                case "Туризм": {
                    document.title = `Новостям и событиям в мире путешествий и отдыха - Петербург Онлайн`;
                    setDesc(
                        "Новостям и событиям в мире путешествий и отдыха. Здесь вы сможете узнать о популярных туристических направлениях, новых интересных местах для путешествий, последних тенденциях в индустрии туризма, а также о мероприятиях и фестивалях, которые стоит посетить. - Петербург Онлайн"
                    );
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Новости о путешествиях и отдыхе",
                        subtitle: "Новостям и событиям в мире путешествий и отдыха. Здесь вы сможете узнать о популярных туристических направлениях, новых интересных местах для путешествий, последних тенденциях в индустрии туризма, а также о мероприятиях и фестивалях, которые стоит посетить",
                    });
                    break;
                }
                case "СВО": {
                    document.title = `Новости и события связанные с специальной военной операцией - Петербург Онлайн`;
                    setDesc("Новости и события связанные с специальной военной операцией. Здесь вы сможете узнать о событиях, связанных с обороной и безопасностью страны в ходе проведения СВО,  ознакомиться с эксклюзивными интервью и комментариями экспертов в этой области. - Петербург Онлайн");
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        category_e: "Специальная военная операция",
                        subtitle: "Новости и события связанные с специальной военной операцией. Здесь вы сможете узнать о событиях, связанных с обороной и безопасностью страны в ходе проведения СВО,  ознакомиться с эксклюзивными интервью и комментариями экспертов в этой области",
                    });
                    break;
                }
                case 'Выставка "Мой Петербург"': {
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        image: vImage,
                        subtitle:
                            "Выставка, открытая губернатором Александром Бегловым и Дмитрием Чернышенко, предлагает погрузиться в атмосферу города и увидеть, за что мы его так любим, каким он был, стал и будет. |В каждом зале каждый из посетителей сможет найти что-то для себя, почувствовать атмосферу города, окунуться в его дела, провести время душевно и с умом, узнать для себя много нового или напомнить про давно забытое старое. |Выставка находится: в музейно-выставочном центре «Россия — Моя история».",
                    });
                    break;
                }
                case "Серебряный возраст": {
                    setFormInfo({
                        ...formInfo,
                        category: request.category,
                        images: [saImage, saImage2],
                        subtitle: `Карта «Серебряный возраст» — часть проекта Губернатора Санкт-Петербурга Александра Беглова «Серебряный возраст». 
                    Проект включает четыре направления: «Здоровье», «Спорт», «Образование» и «Досуг» и направлен на повышение качества жизни людей пенсионного и предпенсионного возраста, увеличение продолжительности жизни петербуржцев.`,
                    });
                    break;
                }
                default: {
                    document.title = `Новости по тегу "${request.category}" - Петербург Онлайн`;
                    setDesc(`${request.category}: последние новости и сведения на сегодня. Новости Петербурга - Петербург Онлайн`);
                    setFormInfo({ ...formInfo, category: request.category });
                }
            }
        } else if (request.text || request.text === "") {
            if (request.text[0] === "#") {
                window.location.href = `/tag/${translit(request.text.slice(1, request.text.length))}`;
            }
            url = "getArticleByText";
        }
        if (url) {
            axios.post(`/${url}`, { ...request, offset: 1, limit: 10 }).then(function (response) {
                if (response.data.author) {
                    setFormInfo({ ...formInfo, author: response.data.author });
                }
                setResult(response.data);
                setFetched(true);
            });
        }
    }, [request]);

    useEffect(() => {
        if (articlesFetching) {
            let url;
            if (request.category) {
                url = "tag";
            } else if (request.text || request.text === "") {
                url = "getArticleByText";
            }
            axios
                .post(`/${url}`, {
                    ...request,
                    offset: articlesOffset,
                    limit: 10,
                })
                .then((response) => {
                    if (response.data.articles.length) {
                        setResult({ articles: [...result.articles, ...response.data.articles], count: response.data.count });
                        setArticlesOffset((prevState) => prevState + 1);
                    } else {
                        setemptyArticles(true);
                    }
                })
                .finally(() => setArticlesFetching(false));
        }
    }, [articlesFetching]);

    useEffect(() => {
        document.addEventListener("scroll", scrollHandler);
        return function () {
            document.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    const scrollHandler = (e) => {
        const footerHeight = document.querySelector("footer").scrollHeight;
        if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) - footerHeight < 300 && !emptyArticles) {
            setArticlesFetching(true);
        }
    };

    return !notFound ? (
        <div className="search">
            <Helmet>
                <meta name="description" content={desc} />
            </Helmet>
            {activeImage && (
                <div
                    className="big_viewer"
                    onClick={(e) => {
                        if (!e.target.closest(".big_viewer_content_image")) {
                            setActiveImage("");
                        }
                    }}
                >
                    <div className="big_viewer_content">
                        <div
                            className="big_viewer_content_close"
                            onClick={() => {
                                setActiveImage("");
                            }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                                    fill="#0F0F0F"
                                />
                            </svg>
                        </div>
                        <div className="big_viewer_content_image">
                            <img src={activeImage} alt="" />
                        </div>
                    </div>
                </div>
            )}
            <div className="container">
                <div className="search__content">
                    <main>
                        <SearchForm search={setRequest} info={formInfo} count={result.count} setActiveImage={setActiveImage}></SearchForm>
                        {fetched ? (
                            Boolean(result.articles.length) ? (
                                <div className="search__result">
                                    {result.articles.map((article, i) => (
                                        <Card key={i} article={article} type="medium"></Card>
                                    ))}
                                </div>
                            ) : (
                                <div className="search__result">
                                    <div className="search__zero">
                                        <div className="search__zero_img">
                                            <img src={noResultIcon} alt="ничего не найдено" />
                                        </div>
                                        <div className="search__zero_text">По вашему запросу ничего не найдено, попробуйте другие варианты</div>
                                    </div>
                                </div>
                            )
                        ) : (
                            <Preloader></Preloader>
                        )}
                    </main>
                    <Timeline></Timeline>
                </div>
            </div>
        </div>
    ) : (
        <NotFound></NotFound>
    );
};

export default Search;
