import { React, useState } from "react";

import "./index.scss";

const QuoteImg = ({ photoUrl, name }) => {
    const webpImg = photoUrl.split(".")[0] + ".webp";
    const [imgChecked, setImgChecked] = useState(false);
    return (
        <div className="article__author_photo">
            <picture>
            <source srcSet={webpImg} alt={name} type="image/webp" />
            <img 
                src={photoUrl?.split('.')[1] === 'png' ? photoUrl?.split('.')[0] + '.jpg' : photoUrl}
                alt={name}
                onError={(e) => {
                    if (!imgChecked) {
                        setImgChecked(true);
                        e.target.parentNode.children[0].srcset = photoUrl?.split('.')[1] === 'png' ? photoUrl?.split('.')[0] + '.jpg' : photoUrl;
                    }
                }}
            />
            </picture>
        </div>
    );
};

export default QuoteImg;
