import React, { useEffect, useState } from 'react';
import './Reforms.scss'
import { RateReformTemplate } from '../Templates/RateReformTemplate';
import { RateReformLinks } from '../Templates/RateReformLinks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import axios from "axios";
import Preloader from '../../Preloader';
import moment from 'moment';
import Photo from "../../Photo";
import classNames from 'classnames';
import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import noResultIcon from '../../../assets/image/no-result-icon.svg'

export const Reforms = () => {
    const [reforms, setReforms] = useState({})
    const [openedArticle, setOpenedArticle] = useState()
    const [isFetched, setIsFetched] = useState(false);
    const [swipers, setSwipers] = useState([]);

    useEffect(() => {
        init({data});
        axios.post("/getReforms", {}).then((res) => {
            res.data.map(reform => {
                reform.Reform_Articles?.map(article => {
                    article.data = JSON.parse(article.data)
                    article.data.blocks?.map(block => {
                        if (block.type === 'paragraph') {
                            block.data.text = prepareLinks(block.data.text);
                        }
                        return block
                    });
                    return article
                });
                return reform
            })
            setReforms(res.data);
            setIsFetched(true);
        });
    }, [])

    function createMarkup(str) {
        return { __html: str };
    }

    function prepareLinks(text) {
        let div = document.createElement('div');
        div.innerHTML = text;
        div.querySelectorAll('.article__link').forEach(block => {
            let a = document.createElement('a');
            const text = block.textContent;
            a.textContent = text
            if (text[0] === "#") {
                a.setAttribute('href', `/tag/${text.slice(1, text.length)}`);
            } else {
                a.setAttribute('href', `/search?text=${text}`);
            }
            a.classList.add('article__link');
            block.insertAdjacentElement('afterend', a);
            block.remove();
        });
        return div.innerHTML
    }

    const renderSlides = (s, swiper) => {
        const li = []
        let r = s
        let c = Math.max(r.Reform_Features.length, r.Reform_Articles.length)
        
        for (let i = 0; i <= c; i++) {
            const cf = r.Reform_Features[i]
            const ca = r.Reform_Articles[i]
            li.push(<SwiperSlide className={ca && openedArticle === ca.id ? "opened_slide" : null}>
                <div className="reform__card_slide">
                    {cf ?
                    <div className="reforms__card_features_slide">
                        <div className="reforms__card_features_icon">
                            <em-emoji id={JSON.parse(cf.icon).id} set={"apple"}></em-emoji>
                        </div>
                        <div className="reforms__card_features_title">{cf.title}</div>
                        <div className="reforms__card_features_text">{cf.text}</div>
                    </div> : null
                    }
                    {ca ?
                    <div
                        className={classNames("reforms__card_article", {"closed": openedArticle !== ca.id})}
                        onClick={(e) => {
                            e.target.closest('.swiper').style.overflowY = openedArticle === ca.id ? 'hidden' : 'auto'
                            setOpenedArticle(openedArticle === ca.id ? 0 : ca.id)
                        }}
                    >
                        <div className="reforms__card_article_wrapper">
                            <div className="reforms__card_article_title">
                                <div className="reforms__card_article_title_text">
                                    {ca.title}
                                </div>
                                <div className="reforms__card_article_title_btn">
                                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9382 8.83505C14.5342 9.23904 13.8792 9.23904 13.4752 8.83505L7.99983 3.35964L2.52443 8.83505C2.12043 9.23904 1.46544 9.23904 1.06145 8.83505C0.657455 8.43106 0.657455 7.77606 1.06145 7.37207L7.26834 1.16517C7.67233 0.761184 8.32733 0.761184 8.73132 1.16517L14.9382 7.37207C15.3422 7.77606 15.3422 8.43106 14.9382 8.83505Z" fill="#2269F4"/>
                                    </svg>
                                </div>
                            </div>
                            <div 
                                className="reforms__card_article_content"
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                {ca.data.blocks?.map((block => {
                                    switch (block.type) {
                                        case "image": {
                                            return <Photo author={block.data.caption} photoUrl={block.data.file.url}></Photo>;
                                        }
                                        case "list": {
                                            if (block.data.style === "ordered") {
                                                return (
                                                    <ol className="article__numbered_list reforms_article__numbered_list">
                                                        {block.data.items.map((item) => {
                                                            return (
                                                                <li>
                                                                    {item.content}
                                                                    {item.items.map((item) => {
                                                                        return (
                                                                            <ol>
                                                                                <li>
                                                                                    {item.content}
                                                                                    {item.items.map((item) => {
                                                                                        return (
                                                                                            <ol>
                                                                                                <li>{item.content}</li>
                                                                                            </ol>
                                                                                        );
                                                                                    })}
                                                                                </li>
                                                                            </ol>
                                                                        );
                                                                    })}
                                                                </li>
                                                            );
                                                        })}
                                                    </ol>
                                                );
                                            } else {
                                                return (
                                                    <ul className="article__bulleted_list reforms_article__bulleted_list">
                                                        {block.data.items.map((item) => {
                                                            return (
                                                                <li>
                                                                    {item.content}
                                                                    {item.items.map((item) => {
                                                                        return (
                                                                            <ul>
                                                                                <li>{item.content}</li>
                                                                            </ul>
                                                                        );
                                                                    })}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                );
                                            }
                                        }
                                        case "paragraph": {
                                            return <p className="article__paragraph reforms_article_paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>;
                                        }
                                        default:
                                    }
                                }))}
                            </div>
                        </div>
                        {/* <div className="reforms__card_article_date">{moment(f.publishDate).format('L') + ' ' + moment(f.publishDate).format('LT')}</div> */}
                    </div>
                : null}
                </div>
            </SwiperSlide>)
        }
        return li
    }

    const addSwiper = (swiper) => {
        setSwipers([...swipers, swiper])
    }

    let swiper
    
    return isFetched ?
        <div className="reforms">
            <RateReformLinks data={reforms} title={"Проекты и реформы"} text={"Раздел сайта с реформами демонстрирует результаты проектов и реформ, которые осуществляются для города и граждан. Вы можете следить за динамикой развития города:  количеством построенных школ, поликлиник,  отремонтированных дорог и многим другим."}/>
                <div className="rr__list">
                    {reforms.map((r, i) => (
                    <RateReformTemplate title={r.title} bg={r.image} id={r.id} icon={r.icon} color={r.color} mainColor={r.mainColor}>
                        <div className='reforms__card_content'>
                            <div className="reforms__card_features">
                                {window.innerWidth > 768 ?
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={'auto'}
                                    modules={[Navigation]}
                                    direction={'vertical'}
                                    // loop={Math.max(r.Reform_Features.length, r.Reform_Articles.length) > 5 && window.innerWidth > 768}
                                    navigation={{
                                        nextEl: `#rate_next_${r.id}`,
                                    }}
                                    onSlideChange={(swiper) => { 
                                        setOpenedArticle(0); 
                                        document.querySelectorAll('.swiper').forEach(s => s.style.overflowY = 'hidden')
                                    }}
                                    onSwiper={(s) => {swiper = s}}
                                    className="reforms__card_features_slider">
                                        {renderSlides(r, swiper).map(s => (s))}
                                        {
                                            r.Reform_Articles.length === 0 ? (
                                                <div className="reforms__empty_articles reforms__empty_articles_desc">
                                                    <img src={noResultIcon} alt="ничего не найдено" />
                                                    <div className="reforms__empty_articles_text">Тут пока нет статей</div>
                                                </div>
                                            ) : null
                                        }
                                </Swiper>
                                :
                                <div
                                    className="reforms__card_features_slider">
                                        {r.Reform_Features?.map(f => (
                                            <SwiperSlide>
                                                <div className="reforms__card_features_slide">
                                                    <div className="reforms__card_features_icon">
                                                        <em-emoji id={JSON.parse(f.icon).id} set={"apple"}></em-emoji>
                                                    </div>
                                                    <div className="reforms__card_features_title">{f.title}</div>
                                                    <div className="reforms__card_features_text">{f.text}</div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                </div>
                                }
                                
                                {Math.max(r.Reform_Features.length, r.Reform_Articles.length) <= 5 ||
                                    <button className="mainpage__slider_nav_btn reforms__card_features_btn_next" id={`rate_next_${r.id}`}>
                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z" fill="#222222"/>
                                        </svg>
                                    </button>
                                }
                            </div>
                            <div className="reforms__card_articles articles_mobile">
                                {/* <div className="reforms__card_articles_title">Cтатьи по теме:</div> */}
                                <ul className="reforms__card_articles_list">
                                    {r.Reform_Articles.length > 0 ? r.Reform_Articles?.map(f => (
                                        <li 
                                            className={classNames("reforms__card_article", {"closed": openedArticle !== f.id})}
                                            onClick={() => {
                                                setOpenedArticle(openedArticle === f.id ? 0 : f.id)
                                            }}
                                        >
                                            <div className="reforms__card_article_title">
                                                <div className="reforms__card_article_title_text">
                                                    {f.title}
                                                </div>
                                                <div className="reforms__card_article_title_btn">
                                                    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9382 8.83505C14.5342 9.23904 13.8792 9.23904 13.4752 8.83505L7.99983 3.35964L2.52443 8.83505C2.12043 9.23904 1.46544 9.23904 1.06145 8.83505C0.657455 8.43106 0.657455 7.77606 1.06145 7.37207L7.26834 1.16517C7.67233 0.761184 8.32733 0.761184 8.73132 1.16517L14.9382 7.37207C15.3422 7.77606 15.3422 8.43106 14.9382 8.83505Z" fill="#2269F4"/>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div 
                                                className="reforms__card_article_content"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {f.data.blocks?.map((block => {
                                                    switch (block.type) {
                                                        case "image": {
                                                            return <Photo author={block.data.caption} photoUrl={block.data.file.url}></Photo>;
                                                        }
                                                        case "list": {
                                                            if (block.data.style === "ordered") {
                                                                return (
                                                                    <ol className="article__numbered_list reforms_article__numbered_list">
                                                                        {block.data.items.map((item) => {
                                                                            return (
                                                                                <li>
                                                                                    {item.content}
                                                                                    {item.items.map((item) => {
                                                                                        return (
                                                                                            <ol>
                                                                                                <li>
                                                                                                    {item.content}
                                                                                                    {item.items.map((item) => {
                                                                                                        return (
                                                                                                            <ol>
                                                                                                                <li>{item.content}</li>
                                                                                                            </ol>
                                                                                                        );
                                                                                                    })}
                                                                                                </li>
                                                                                            </ol>
                                                                                        );
                                                                                    })}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ol>
                                                                );
                                                            } else {
                                                                return (
                                                                    <ul className="article__bulleted_list reforms_article__bulleted_list">
                                                                        {block.data.items.map((item) => {
                                                                            return (
                                                                                <li>
                                                                                    {item.content}
                                                                                    {item.items.map((item) => {
                                                                                        return (
                                                                                            <ul>
                                                                                                <li>{item.content}</li>
                                                                                            </ul>
                                                                                        );
                                                                                    })}
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                );
                                                            }
                                                        }
                                                        case "paragraph": {
                                                            return <p className="article__paragraph reforms_article_paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>;
                                                        }
                                                        default:
                                                    }
                                                }))}
                                            </div>
                                            {/* <div className="reforms__card_article_date">{moment(f.publishDate).format('L') + ' ' + moment(f.publishDate).format('LT')}</div> */}
                                        </li>
                                    )) 
                                    :
                                    <div className="reforms__empty_articles">
                                        <img src={noResultIcon} alt="ничего не найдено" />
                                        <div className="reforms__empty_articles_text">Тут пока нет статей</div>
                                    </div>
                                    }
                                </ul>
                            </div>

                        </div>
                    </RateReformTemplate>
                    ))}
                </div>
            </div>
            :
            <Preloader></Preloader>
}