import React, { useEffect, useState } from 'react';
import './Rate.scss'
import { RateReformTemplate } from '../Templates/RateReformTemplate';
import { RateReformLinks } from '../Templates/RateReformLinks';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import axios from "axios";
import Preloader from '../../Preloader';
import { RateCard } from './RateCard'

export const Rate = () => {

    const [data, setData] = useState({})
    const [isFetched, setIsFetched] = useState(false);

    useEffect(() => {
        axios.post("/getRatings", {}).then((data) => {
            setData(data.data);
            setIsFetched(true);
        });
    }, [])

    return isFetched ?
        <div className="rate">
            <RateReformLinks data={data} title={"Рейтинг культурной столицы"} text={"Раздел сайта с рейтингами и номинациями, которыми награжден Санкт-Петербург. Показывает, в чем город лучший: культура, транспорт, технологии, образование, архитектура, туризм, проекты и спорт."}/>
            <div className="rr__list">
                {data.map(r => (
                <RateReformTemplate title={r.title} bg={r.image} id={r.id} icon={r.icon} color={r.color} mainColor={r.mainColor}>
                    { r.Rating_Cards?.length > 0 &&
                    <div className='rate__cards'>
                        <Swiper
                            spaceBetween={24}
                            slidesPerView={'auto'}
                            modules={[Navigation]}
                            centerInsufficientSlides={true}
                            navigation={{
                                nextEl: `#rate_next_${r.id}`,
                                prevEl: `#rate_prev_${r.id}`,
                            }}
                            className="rate__cards_swiper">
                                {r.Rating_Cards?.map(c => (
                                <SwiperSlide>
                                    <RateCard c={c} />
                                </SwiperSlide>
                                ))}
                        </Swiper>
                        <div className="rate__cards_nav">
                            <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_prev" id={`rate_prev_${r.id}`}>
                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z" fill="#222222"/>
                                </svg>
                            </button>
                            <button className="mainpage__slider_nav_btn mainpage__slider_nav_btn_next" id={`rate_next_${r.id}`}>
                                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z" fill="#222222"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    }
                </RateReformTemplate>
                ))}
            </div>
        </div> 
        :
        <Preloader></Preloader>
}