import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import {Helmet} from "react-helmet";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./index.scss";

const Contacts = () => {
    const [contacts, setContacts] = useState();

    useEffect(() => {
        let getContacts = axios.post("/getInfo", {}).then((data) => {
            setContacts(data.data);
        });
    }, []);

    return (
        <div className="wrapper">
            <Helmet>
                <title>Контаĸты - Петербург Онлайн</title>
                <meta name='description' content='На данной странице представлена ĸонтаĸтная информация проеĸта «Петербург Онлайн». Адреса, номера телефонов, адреса элеĸтронной почты для связи, а таĸже режим работы.' />
            </Helmet>
            <div className="contacts__container">
                <h1 className="contacts__title">Контакты</h1>
                <div className="contacts__items">
                    <div className="contacts__item">
                        <h6 className="contacts__subtitle">Адрес</h6>
                        <p className="contacts__text">{contacts?.address}</p>
                    </div>
                    <div className="contacts__item" id="contacts__phone">
                        <h6 className="contacts__subtitle">Телефон</h6>
                        <a href="tel:+74957387323" className="contacts__link">
                            {contacts?.phone}
                        </a>
                    </div>
                    <div className="contacts__item" id="contacts__email">
                        <h6 className="contacts__subtitle">Электронная почта</h6>
                        <a href="mailto:info@company-name.ru" className="contacts__link">
                            {contacts?.email}
                        </a>
                    </div>
                </div>
                <iframe className="contacts__map" src="https://yandex.ru/map-widget/v1/?ll=30.092322%2C59.940675&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1MzAwMDA5MxIr0KDQvtGB0YHQuNGPLCDQodCw0L3QutGCLdCf0LXRgtC10YDQsdGD0YDQsyIKDW-G8kEVfcFvQg%2C%2C&z=9.75" width="100%" height="470" frameborder="0"></iframe>
                <h4 className="ad__title">Размещение рекламы</h4>
                <div className="contacts__items">
                    <div className="contacts__item">
                        <h6 className="contacts__subtitle">Телефон</h6>
                        <a href="tel:+74957387323" className="contacts__link">
                            {contacts?.phoneBigness}
                        </a>
                    </div>
                    <div className="contacts_item" id="ad__email">
                        <h6 className="contacts__subtitle">Электронная почта</h6>
                        <a href="mailto:reklama@company-name.ru" className="contacts__link">
                            {contacts?.emailBigness}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
