import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";
import "moment/locale/ru";
import videoExtensions from "video-extensions";
import translit from "../../../utils/translit";

import "./index.scss";
import axios from "axios";

const Card = ({ type, article }) => {
    let title;
    switch (type) {
        case "img-down": {
            title = <h5 className="card__title">{article?.title}</h5>;
            break;
        }
        case "author": {
            title = <h5 className="card__title">{article?.title}</h5>;
            break;
        }
        case "title-right": {
            title = <h6 className="card__title">{article?.title}</h6>;
            break;
        }
        case "title-bottom": {
            title = <p className="card__title">{article?.title}</p>;
            break;
        }
        default: {
            title = <h4 className="card__title">{article?.title}</h4>;
        }
    }

    let date = new Date(article?.publishDate);
    moment.locale("ru");
    let createdAt = moment(date).format("L") + " " + moment(date).format("LT");

    let photo;
    if (article?.data) {
        JSON.parse(article.data).blocks?.forEach((block) => {
            if ((block.type = "image" && !photo)) {
                photo = block.data?.file?.url;
            }
        });
        if (!photo) {
            JSON.parse(article.data).blocks?.forEach((block) => {
                if ((block.type = "carousel" && !photo)) {
                    photo = block.data[0]?.url;
                }
            });
        }
    }
    const webpImg = photo?.split(".")[0] + ".webp";

    if (article && article.Article_authors) {
        article.author = article.Article_authors[0];
    }

    function createMarkup(str) {
        return { __html: str };
    }
    if (article && article.text) {
        let div = document.createElement("div");
        div.innerHTML = article.text;
        article.text = div.textContent;
        div.remove();
    }

    const [imgChecked, setImgChecked] = useState(false);

    return (
        <article
            className={classnames("card", {
                big: type === "big",
                medium: type === "medium",
                "img-down": type === "img-down",
                "title-right": type === "title-right",
                "title-bottom": type === "title-bottom",
                "title-bottom-ns": type === "title-bottom-ns",
                author: type === "author",
                "only-title": type === "only-title",
            })}
        >
            <a href={`/article/${article?.id}`} className="card__photo">
                {videoExtensions.includes(photo?.split(".").pop()) ? (
                    <video>
                        <source src={photo + "#t=0.001"} alt={article?.title} poster />
                    </video>
                ) : (
                    <picture>
                        <source srcSet={webpImg} alt={article?.title} type="image/webp" />
                        <img
                            alt={article?.title}
                            src={photo?.split('.')[1] === 'png' ? photo?.split('.')[0] + '.jpg' : photo}
                            onError={(e) => {
                                if (!imgChecked) {
                                    setImgChecked(true);
                                    e.target.parentNode.children[0].srcset = photo?.split('.')[1] === 'png' ? photo?.split('.')[0] + '.jpg' : photo
                                }
                            }}
                        />
                    </picture>
                )}
            </a>
            <div className="card__text_content">
                <div className="card__tag_and_time">
                    <a href={`/tag/${translit(article?.Category?.name)}`} className="card__tag">
                        {article?.Category?.name}
                    </a>
                    <div className="card__time">, {createdAt}</div>
                </div>
                <a href={`/article/${article?.id}`} className="card__title_and_text">
                    {title}
                    <p className="card__text">{article?.text}</p>
                </a>
                {article?.author && (
                    <a href={`/search?author=${article?.author.id}`} className="card__author">
                        <div className="card__author_photo">
                            <img src={`/uploads/${article?.author.photo}`} alt={`${article?.author.name} ${article?.author.lastname}`} />
                        </div>
                        <author className="card__author_name">
                            {article?.author.name} {article?.author.lastname}
                        </author>
                    </a>
                )}
                <ul className="card__socials">
                    <li className="tg">
                        <noindex>
                            <a rel='nofollow' href={"https://t.me/share/url?url=" + window.location.host + `/article/${article?.id}` + "&text=" + article?.title}>
                                <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.2208 0.0975007L1.44583 5.795C0.437498 6.2 0.443331 6.7625 1.26083 7.01333L5.05416 8.19667L13.8308 2.65917C14.2458 2.40667 14.625 2.5425 14.3133 2.81917L7.2025 9.23667H7.20083L7.2025 9.2375L6.94083 13.1475C7.32416 13.1475 7.49333 12.9717 7.70833 12.7642L9.55083 10.9725L13.3833 13.8033C14.09 14.1925 14.5975 13.9925 14.7733 13.1492L17.2892 1.2925C17.5467 0.260001 16.895 -0.207499 16.2208 0.0975007Z"
                                        fill="#B9B9B9"
                                    />
                                </svg>
                            </a>
                        </noindex>
                    </li>
                    <li className="vk">
                        <noindex>
                            <a rel='nofollow' href={`https://vk.com/share.php?url=${window.location.host}/article/${article?.id}&title=${article?.title}`}>
                                <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.9826 0.712499C17.0992 0.324999 16.9826 0.0408325 16.4309 0.0408325H14.6034C14.1384 0.0408325 13.9259 0.286666 13.8092 0.556666C13.8092 0.556666 12.8801 2.8225 11.5634 4.29167C11.1384 4.71917 10.9442 4.85417 10.7126 4.85417C10.5967 4.85417 10.4284 4.71917 10.4284 4.33167V0.712499C10.4284 0.247499 10.2942 0.0408325 9.90675 0.0408325H7.03508C6.74508 0.0408325 6.57008 0.255833 6.57008 0.460833C6.57008 0.900833 7.22841 1.0025 7.29591 2.2425V4.9325C7.29591 5.52167 7.19008 5.62917 6.95675 5.62917C6.33758 5.62917 4.83091 3.355 3.93675 0.751666C3.76258 0.245833 3.58675 0.0416662 3.12008 0.0416662H1.29341C0.770915 0.0416662 0.666748 0.2875 0.666748 0.5575C0.666748 1.0425 1.28591 3.4425 3.55091 6.61667C5.06091 8.78417 7.18675 9.95917 9.12341 9.95917C10.2842 9.95917 10.4276 9.69833 10.4276 9.24833V7.61C10.4276 7.08833 10.5384 6.98333 10.9059 6.98333C11.1759 6.98333 11.6409 7.12 12.7251 8.16417C13.9634 9.4025 14.1684 9.95833 14.8642 9.95833H16.6909C17.2126 9.95833 17.4734 9.6975 17.3234 9.1825C17.1592 8.67 16.5676 7.92417 15.7826 7.04167C15.3559 6.53833 14.7184 5.99667 14.5242 5.72583C14.2534 5.37667 14.3317 5.2225 14.5242 4.9125C14.5251 4.91333 16.7509 1.77833 16.9826 0.712499Z"
                                        fill="#B9B9B9"
                                    />
                                </svg>
                            </a>
                        </noindex>
                    </li>
                    {/* <li className="tw">
                        <a href="#">
                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.3609 3.66415C15.3717 3.80999 15.3717 3.95499 15.3717 4.09999C15.3717 8.53749 11.9942 13.6508 5.82175 13.6508C3.92008 13.6508 2.15341 13.1 0.666748 12.1433C0.936748 12.1742 1.19675 12.185 1.47758 12.185C2.98841 12.1886 4.45645 11.6834 5.64508 10.7508C4.9446 10.7381 4.26556 10.507 3.70278 10.0898C3.14 9.6725 2.72157 9.0899 2.50591 8.42332C2.71341 8.45415 2.92175 8.47499 3.14008 8.47499C3.44091 8.47499 3.74341 8.43332 4.02425 8.36082C3.26405 8.20733 2.58048 7.79528 2.08976 7.19472C1.59905 6.59417 1.33149 5.84219 1.33258 5.06665V5.02499C1.78008 5.27415 2.29925 5.42999 2.84925 5.45082C2.38849 5.14464 2.01068 4.72917 1.74953 4.24148C1.48838 3.75378 1.35201 3.20903 1.35258 2.65582C1.35258 2.03249 1.51841 1.46082 1.80925 0.962485C2.6527 2 3.70467 2.84879 4.89703 3.45387C6.08939 4.05895 7.39555 4.40683 8.73091 4.47499C8.67925 4.22499 8.64758 3.96582 8.64758 3.70582C8.64736 3.26495 8.73404 2.82837 8.90265 2.42102C9.07126 2.01367 9.3185 1.64355 9.63024 1.33181C9.94198 1.02007 10.3121 0.772828 10.7194 0.604217C11.1268 0.435606 11.5634 0.348933 12.0042 0.349152C12.9709 0.349152 13.8434 0.754152 14.4567 1.40915C15.2082 1.26383 15.9289 0.989504 16.5867 0.598319C16.3363 1.374 15.8115 2.03174 15.1109 2.44832C15.7774 2.3723 16.4288 2.19703 17.0434 1.92832C16.5844 2.59757 16.0155 3.18444 15.3609 3.66415Z"
                                    fill="#B9B9B9"
                                />
                            </svg>
                        </a>
                    </li>
                    <li className="fb">
                        <a href="#">
                            <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.16427 15.4975V8.66749H7.46844L7.81094 5.99332H5.16427V4.28999C5.16427 3.51832 5.37927 2.98999 6.48677 2.98999H7.89011V0.605822C7.20731 0.532648 6.52098 0.497316 5.83427 0.499988C3.79761 0.499988 2.39927 1.74332 2.39927 4.02582V5.98832H0.110107V8.66249H2.40427V15.4975H5.16427Z" fill="#B9B9B9" />
                            </svg>
                        </a>
                    </li> */}
                    <li className="ok">
                        <noindex>
                            <a rel='nofollow' href={`https://connect.ok.ru/offer?url=${window.location.host}/article/${article?.id}&title=${article?.title}`}>
                                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.98842 9.28416C2.63425 9.28416 0.677582 7.32916 0.677582 5.01582C0.677582 2.62249 2.63425 0.666656 4.98925 0.666656C7.42425 0.666656 9.30008 2.62166 9.30008 5.01582C9.29589 6.1517 8.84087 7.23944 8.03503 8.03997C7.22918 8.8405 6.13846 9.28831 5.00258 9.28499L4.98842 9.28416ZM4.98842 3.18082C3.99175 3.18082 3.23342 4.01832 3.23342 5.01666C3.23342 6.01332 3.99175 6.77166 4.98925 6.77166C6.02675 6.77166 6.74508 6.01332 6.74508 5.01666C6.74592 4.01749 6.02675 3.18082 4.98842 3.18082ZM6.70508 12.8358L9.14091 15.19C9.62008 15.7075 9.62008 16.4658 9.14091 16.945C8.62258 17.4625 7.82342 17.4625 7.42425 16.945L4.98925 14.5508L2.63425 16.945C2.39508 17.1842 2.07508 17.3033 1.71508 17.3033C1.43591 17.3033 1.11675 17.1833 0.836748 16.945C0.357582 16.4658 0.357582 15.7075 0.836748 15.1892L3.31175 12.835C2.41794 12.5703 1.56053 12.1955 0.759248 11.7192C0.160081 11.4 0.0409151 10.6025 0.360082 10.0033C0.759248 9.40499 1.47758 9.24582 2.11675 9.64499C2.98225 10.1732 3.97657 10.4526 4.9905 10.4526C6.00443 10.4526 6.99875 10.1732 7.86425 9.64499C8.50342 9.24582 9.26092 9.40499 9.62008 10.0033C9.98008 10.6025 9.81925 11.3992 9.26008 11.7192C8.50258 12.1983 7.62425 12.5575 6.70592 12.8367L6.70508 12.8358Z"
                                        fill="#B9B9B9"
                                    />
                                </svg>
                            </a>
                        </noindex>
                    </li>
                </ul>
            </div>
        </article>
    );
};

export default Card;
