import { React, useState } from "react";

const Image = ({ src, alt }) => {
    const webpImg = src.split(".")[0] + ".webp";
    const [imgChecked, setImgChecked] = useState(false);
    return (
            <picture>
                <source srcSet={webpImg} alt={alt} type="image/webp" />
                <img
                    src={src?.split('.')[1] === 'png' ? src?.split('.')[0] + '.jpg' : src}
                    alt={alt}
                    onError={(e) => {
                        if (!imgChecked) {
                            setImgChecked(true);
                            e.target.parentNode.children[0].srcset = src?.split('.')[1] === 'png' ? src?.split('.')[0] + '.jpg' : src;
                        }
                    }}
                />
            </picture>
    );
};

export default Image;
