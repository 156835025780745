import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "axios";
import {Helmet} from "react-helmet";

import './index.scss' 
import Photo from '../Photo';
import Preloader from '../Preloader';

const AboutUs = () => {
    const [article, setArticle] = useState({});
    const [isFetched, setFetched] = useState(false);

    useEffect(() => {
        axios.post("/getAbout", {})
        .then(function (response) {
            setArticle({data: JSON.parse(response.data.data)});
            setFetched(true);
        })
    }, []);

    const handleVideoClick = (event) => {
        let video = event.target.closest(".article__video").querySelector("video");
        let btn = event.target.closest(".article__video_btn");
        btn.style.display = "none";
        video.setAttribute("controls", "controls");
        video.play();
    };

    function createMarkup(str) {
        return { __html: str };
    }

    return (   
        <div className='about_wrapper'>
        {isFetched ?
            <div className="container">
                <Helmet>
                    <title>О Проеĸте - Петербург Онлайн</title>
                    <meta name='description' content='На данной странице представлена информация о проеĸте «Петербург Онлайн». Будем рады услышать ваши предложения по сотрудничеству, а таĸже пожелания по работе и развитию проеĸта.' />
                </Helmet>
                <div className="article__content">
                    <main>
                        <div className="article__wrapper">
                            <div className="article__container">
                                <article>
                                    <h1 className="article__title">О компании</h1>
                                    {article.data.blocks?.map((block) => {
                                        switch (block.type) {
                                            case "header": {
                                                switch (block.data.level) {
                                                    case 2: {
                                                        return <h2>{block.data.text}</h2>;
                                                    }
                                                    case 3: {
                                                        return <h3>{block.data.text}</h3>;
                                                    }
                                                    case 4: {
                                                        return <h4>{block.data.text}</h4>;
                                                    }
                                                    case 5: {
                                                        return <h5>{block.data.text}</h5>;
                                                    }
                                                    case 6: {
                                                        return <h6>{block.data.text}</h6>;
                                                    }
                                                    default: {}
                                                }
                                                break;
                                            }
                                            case "image": {
                                                return <Photo author={block.data.caption} photoUrl={block.data.file.url}></Photo>;
                                            }
                                            case "colored": {
                                                return (
                                                    <div className="article__colored">
                                                        <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>
                                                    </div>
                                                );
                                            }
                                            case "quote": {
                                                return (
                                                    <div className="article__quote">
                                                        <div className="article__quote_text">
                                                            <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.description)}></p>
                                                        </div>
                                                        <div className="article__author">
                                                            <div className="article__author_photo">
                                                                <img src={block.data.photo} alt={block.data.name} />
                                                            </div>
                                                            <p className="article__author_name">{block.data.name}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            case "list": {
                                                if (block.data.style === "ordered") {
                                                    return (
                                                        <ol className="article__numbered_list">
                                                            {block.data.items.map((item) => {
                                                                return (
                                                                    <li>
                                                                        {item.content}
                                                                        {item.items.map((item) => {
                                                                            return (
                                                                                <ol>
                                                                                    <li>
                                                                                        {item.content}
                                                                                        {item.items.map((item) => {
                                                                                            return (
                                                                                                <ol>
                                                                                                    <li>{item.content}</li>
                                                                                                </ol>
                                                                                            );
                                                                                        })}
                                                                                    </li>
                                                                                </ol>
                                                                            );
                                                                        })}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ol>
                                                    );
                                                } else {
                                                    return (
                                                        <ul className="article__bulleted_list">
                                                            {block.data.items.map((item) => {
                                                                return (
                                                                    <li>
                                                                        {item.content}
                                                                        {item.items.map((item) => {
                                                                            return (
                                                                                <ul>
                                                                                    <li>{item.content}</li>
                                                                                </ul>
                                                                            );
                                                                        })}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    );
                                                }
                                            }
                                            case "carousel": {
                                                return (
                                                    <div className="article__slider">
                                                        <button className="article__slider_nav_btn article__slider_nav_btn_prev article__slider_prev">
                                                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                                    fill="#222222"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <Swiper
                                                            spaceBetween={20}
                                                            slidesPerView={"auto"}
                                                            modules={[Navigation]}
                                                            navigation={{
                                                                nextEl: ".article__slider_next",
                                                                prevEl: ".article__slider_prev",
                                                            }}
                                                            className="artilce__swiper"
                                                        >
                                                            {block.data.map((img) => {
                                                                return (
                                                                    <SwiperSlide>
                                                                        <Photo author={img.caption} photoUrl={img.url}></Photo>
                                                                    </SwiperSlide>
                                                                );
                                                            })}
                                                        </Swiper>
                                                        <button className="article__slider_nav_btn article__slider_nav_btn_next article__slider_next">
                                                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M1.16499 14.9385C0.761002 14.5345 0.761002 13.8795 1.16499 13.4755L6.6404 8.00008L1.16499 2.52467C0.761002 2.12068 0.761002 1.46568 1.16499 1.06169C1.56898 0.657697 2.22398 0.657697 2.62797 1.06169L8.83487 7.26859C9.23886 7.67258 9.23886 8.32757 8.83487 8.73156L2.62797 14.9385C2.22398 15.3425 1.56898 15.3425 1.16499 14.9385Z"
                                                                    fill="#222222"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                );
                                            }
                                            case "paragraph": {
                                                return <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>;
                                            }
                                            case "video": {
                                                return <div className="article__video">
                                                            <figure>
                                                                <p className='img-container'>
                                                                    <video className='article__video' src={block.data.file.url} poster="true"></video>
                                                                    <div className="article__video_btn" onClick={(e) => {
                                                                        handleVideoClick(e);
                                                                    }}>
                                                                        <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M0.333344 38.5301V3.47006C0.33317 2.88355 0.487752 2.30737 0.781483 1.79971C1.07521 1.29205 1.49769 0.870881 2.00626 0.578724C2.51483 0.286568 3.09148 0.13377 3.67799 0.135761C4.2645 0.137752 4.8401 0.294461 5.34668 0.590063L35.3967 18.1234C35.8995 18.4171 36.3166 18.8373 36.6065 19.3423C36.8963 19.8473 37.0489 20.4194 37.0489 21.0017C37.0489 21.584 36.8963 22.1561 36.6065 22.6611C36.3166 23.1661 35.8995 23.5864 35.3967 23.8801L5.34668 41.4101C4.8401 41.7057 4.2645 41.8624 3.67799 41.8644C3.09148 41.8664 2.51483 41.7136 2.00626 41.4214C1.49769 41.1292 1.07521 40.7081 0.781483 40.2004C0.487752 39.6928 0.33317 39.1166 0.333344 38.5301Z" fill="white"/>
                                                                        </svg>
                                                                    </div>
                                                                </p>
                                                                <figcaption className='img-description'>Видео: {block.data.caption}</figcaption>
                                                            </figure>
                                                        </div>
                                            }
                                            default: {}
                                        }
                                    })}
                                </article>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
         : 
            <Preloader></Preloader>
        }
        </div>
    )
}

export default AboutUs;