import React, { useState } from "react";
import "./Rate.scss";

export const RateCard = ({ c }) => {
    const webpImg = "/uploads/" + c.image?.split(".")[1] === '.svg' ? c.image : c.image?.split(".")[0] + ".webp";
    const [imgChecked, setImgChecked] = useState(false);

    function createMarkup(str) {
        return { __html: str };
    }

    return (
        <div className="rate__cards_swiper_card">
            <div className="rate__cards_swiper_card_image">
                <picture>
                    <source srcSet={webpImg} alt={c.title} type="image/webp" />
                    <img
                        src={`/uploads/${c.image?.split(".")[1] === "png" ? c.image?.split(".")[0] + ".jpg" : c.image}`}
                        alt={c.title}
                        onError={(e) => {
                            if (!imgChecked) {
                                setImgChecked(true);
                                e.target.parentNode.children[0].srcset = `/uploads/${c.image?.split(".")[1] === "png" ? c.image?.split(".")[0] + ".jpg" : c.image}`;
                            }
                        }}
                    />
                </picture>
                <div className="rate__cards_swiper_card_year">{c.year}</div>
            </div>
            <div className="rate__cards_swiper_card_text">
            {JSON.parse(c.text).blocks?.map((block) => {
                switch (block.type) {
                    case "paragraph": {
                        return <p className="article__paragraph" dangerouslySetInnerHTML={createMarkup(block.data.text)}></p>;
                    }
                    default:
                }
            })}
            </div>
            {c.link ?
                <a rel="nofollow" href={c.link} className="rate__cards_swiper_card_link">
                    {c.link_text}
                </a>
                :
                c.link_text &&
                <div className="rate__cards_swiper_card_link div">
                    {c.link_text}
                </div>
            }
        </div>
    );
};
