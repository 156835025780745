export default function translit(word, reverse = false){
    const converter = {
        'а': 'a',
        'б': 'b',
        'в': 'v',
        'г': 'g',
        'д': 'd',
        'е': 'e',
        'ё': 'je',
        'ж': 'zh',
        'з': 'z',
        'и': 'i',
        'й': 'y',
        'к': 'k',
        'л': 'l',
        'м': 'm',
        'н': 'n',
        'о': 'o',
        'п': 'p',
        'р': 'r',
        'с': 's',
        'т': 't',
        'у': 'u',
        'ф': 'f',
        'х': 'kh',
        'ц': 'c',
        'ч': 'ch',
        'ш': 'sh',
        'щ': 'jsh',
        'ъ': 'hh',
        'ы': 'ih',
        'ь': 'jh',
        'э': 'eh',
        'ю': 'ju',
        'я': 'ja',
        ' ': '-',
    };
    if (!reverse) {
        let answer = '';
        for (let i = 0; i < word?.length; i++) {
            const isUpperCase = word[i].toUpperCase() === word[i];
            const ch = word[i].toLowerCase();
            if (converter[ch] === undefined){
                answer += word[i];
            } else {
                answer += isUpperCase ? converter[ch].toUpperCase() : converter[ch];
            }
        }
        return answer;
    } else {
        for (let i = 0; i < word.length; i++) {
            if (converter[word[i].toLowerCase()]) {
                return 404;
            }
        }
        let answer = '';
        const rc = {};
        Object.entries(converter).forEach(([key, value]) => {
            rc[value] = key
        });
        for (let i = 0; i < word.length;) {
            let ch = word[i].toLowerCase();
            const isUpperCase = word[i].toUpperCase() === word[i];
            if (ch === 'j') {
                i++;
                ch = word[i].toLowerCase();
                const isUpperCase = word[i].toUpperCase() === word[i];
                switch(ch) {
                    case 'e': {
                        answer += isUpperCase ? 'Ё' : 'ё';
                        break;
                    }
                    case 's': {
                        answer += isUpperCase ? 'Щ' : 'щ';
                        i++;
                        if (word[i].toLowerCase() !== 'h') {
                            throw new Error()
                        }
                        break;
                    }
                    case 'h': {
                        answer += isUpperCase ? 'Ь' : 'ь';
                        break;
                    }
                    case 'u': {
                        answer += isUpperCase ? 'Ю' : 'ю';
                        break;
                    }
                    case 'a': {
                        answer += isUpperCase ? 'Я' : 'я';
                        break;
                    }
                    default: {
                        throw new Error();
                    }
                }
            } else if(i+1 < word.length && word[i+1].toLowerCase() === 'h' && !(i+2 < word.length && word[i+2].toLowerCase() === 'h')){
				switch (ch){
					case 'z': answer += isUpperCase ? 'Ж' : 'ж'; break;
					case 'k': answer += isUpperCase ? 'Х' : 'х'; break;
					case 'c': answer += isUpperCase ? 'Ч' : 'ч'; break;
					case 's': answer += isUpperCase ? 'Ш' : 'ш'; break;
					case 'e': answer += isUpperCase ? 'Э' : 'э'; break;
					case 'h': answer += isUpperCase ? 'Ъ' : 'ъ'; break;
					case 'i': answer += isUpperCase ? 'Ы' : 'ы'; break;
					default: throw new Error("Illegal transliterated symbol at position "+i);
				}
				i++;
			} else if (rc[ch]) {
                answer += isUpperCase ? rc[ch]?.toUpperCase() : rc[ch];
			} else {
                answer += isUpperCase ? ch.toUpperCase() : ch;
            }
            i++;            
        }
        return answer;
    }
}